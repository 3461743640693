@import "../../sass/global/variables";
@import "../../sass/global/bootstrap-variables";
@import "../../sass/global/mixins";
@import "../../sass/global/functions";
@import "bootstrap/scss/mixins";

// japanese
@include font-face($font-primary, $lang-font-path + "noto-sans/noto-sans-jp-v28-latin_japanese/regular", 400, normal, woff woff2 eot svg);
@include font-face($font-primary-italic, $lang-font-path + "noto-sans/noto-sans-jp-v28-latin_japanese/regular", 400, normal, woff woff2 eot svg);
@include font-face($font-primary-bold, $lang-font-path + "noto-sans/noto-sans-jp-v28-latin_japanese/bold", 700, normal, woff woff2 eot svg);
@include font-face($font-primary-heavy, $lang-font-path + "noto-sans/noto-sans-jp-v28-latin_japanese/bold", 700, normal, woff woff2 eot svg);

@include font-face($font-secondary, $lang-font-path + "noto-sans/noto-sans-jp-v28-latin_japanese/regular", 400, normal, woff woff2 eot svg);
@include font-face($font-secondary-bold, $lang-font-path + "noto-sans/noto-sans-jp-v28-latin_japanese/bold", 700, normal, woff woff2 eot svg);

@include font-face($font-tertiary, $lang-font-path + "noto-sans/noto-sans-jp-v28-latin_japanese/regular", 400, normal, woff woff2 eot svg);
@include font-face($font-tertiary-italic, $lang-font-path + "noto-sans/noto-sans-jp-v28-latin_japanese/regular", 400, normal, woff woff2 eot svg);
@include font-face($font-tertiary-bold, $lang-font-path + "noto-sans/noto-sans-jp-v28-latin_japanese/bold", 700, normal, woff woff2 eot svg);

// font weights
@import "_weights-noto";

// japanese-specific rules
.ja-jp {
  .font-family-primary-italic {
    font-family: $font-primary-italic, "NotoSerif-Regular", serif !important;
  }

  .font-family-tertiary-italic {
    font-family: $font-tertiary-italic, "NotoSans-Regular", sans-serif !important;
  }

  .h1 {
    font-size: rem(66);
    @include media-breakpoint-down(sm) {
      font-size: rem(42);
    }
  }
  .h2 {
    font-size: rem(42);
    @include media-breakpoint-down(sm) {
      font-size: rem(28);
    }
  }
  .h3 {
    font-size: rem(42);
    @include media-breakpoint-down(sm) {
      font-size: rem(28);
    }
  }
  .h4 {
    font-size: rem(28);
  }

  .h5 {
    font-size: rem(28);
  }

  .h6 {
    font-size: rem(18);
  }

  .h7 {
    font-size: rem(14);
  }

  .h8 {
    font-size: rem(16);
  }

  .d1 {
    font-size: rem(80);
  }

  .b1 {
    font-size: rem(16);
  }

  .b2 {
    font-size: rem(16);
  }

  .b3 {
    font-size: rem(22);
  }

  .b4 {
    font-size: rem(14);
  }

  .b5 {
    font-size: rem(14);
  }

  .b6 {
    font-size: rem(14);
  }

  .b7 {
    font-size: rem(14);
  }
  .b8 {
    font-size: rem(12);
  }
  .b9 {
    font-size: rem(12);
  }
  .b10 {
    font-size: rem(11);
  }
  .n1 {
    font-size: rem(18);
  }
  .n2 {
    font-size: rem(16);
  }

  .f1 {
    font-size: rem(12);
  }

  .f2 {
    font-size: rem(12);
  }

  .cta-typography {
    font-size: rem(14)
  }
}
