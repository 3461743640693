@function rem($size: 16) {
  $remSize: $size / 16;
  @return #{$remSize}rem;
}

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1)+$replace +str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}